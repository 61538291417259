<template>
  <v-app translate="no">
    <meta
      http-equiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
    <!-- rgb(0, 97, 172) -->
    <div translate="no">
      <transition name="component-fade" mode="out-in">
        <div v-if="check">
          <nav
            class="navbar navbar-expand-md navbar-light"
            style="background-color: #0048ac; padding: 2px"
          >
            <div
              style="
                text-align: center;
                font-weight: bold;
                font-size: 14px;
                padding-left: 10px;
                color: white;
              "
              translate="no"
            >
              ACT
              <div style="font-size: 10px" translate="no">
                All-in-one Control Tool
              </div>
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div
                class="navbar-nav mr-auto"
                style="
                  display: flex;
                  flex-wrap: wrap;
                  color: white;
                  margin-bottom: -15px;
                "
              >
                <ul style="display: flex; flex-wrap: wrap">
                  <li
                    v-if="check && selectedClass.classID != 'violations'"
                    class="nav-item"
                  >
                    <v-btn to="/dashboard" style="color: white" text
                      >ГЛАВНАЯ</v-btn
                    >
                  </li>
                  <li
                    v-if="check && selectedClass.classID != 'violations'"
                    class="nav-item"
                  >
                    <v-btn to="/Statistic" text style="color: white">
                      Статистика
                    </v-btn>
                  </li>

                  <li class="nav-item">
                    <v-btn to="/Violations" text style="color: white">
                      Нарушения
                    </v-btn>
                  </li>
                  <li class="nav-item">
                    <v-btn
                      to="/inventoryControlPanel"
                      v-if="selectedClass.classID == 'violations'"
                      style="color: white"
                      text
                    >
                      Инветаризация
                    </v-btn>
                  </li>

                  <li
                    v-if="check && selectedClass.classID != 'violations'"
                    class="nav-item"
                  >
                    <v-btn to="/Clubs" text style="color: white">
                      Доп. образование
                    </v-btn>
                  </li>

                  <li class="nav-item" style="margin-left: 5px">
                    <div class="dropdown">
                      <button
                        class="btn btn-outline-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="border-color: white"
                        color="white"
                      >
                        ДРУГИЕ СЕРВИСЫ
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                        style="padding: 2px"
                      >
                        <v-btn
                          href="https://web.vk.me"
                          target="_blank"
                          style="
                            background-color: #8b00ff;
                            border: #8b00ff;
                            color: white;
                            margin: 3px;
                          "
                          text
                        >
                          СФЕРУМ &nbsp;
                          <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 24.00 24.00"
                            id="meteor-icon-kit__regular-external-link"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#000000"
                            stroke-width="0.00024000000000000003"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                fill="white"
                              ></path>
                            </g>
                          </svg>
                        </v-btn>
                        <v-btn
                          href="https://www.avsu.ru/loginteacher/"
                          target="_blank"
                          style="
                            color: white;
                            background-color: #fcc02c;
                            margin: 3px;
                          "
                          text
                        >
                          Аксиома &nbsp;
                          <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 24.00 24.00"
                            id="meteor-icon-kit__regular-external-link"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#000000"
                            stroke-width="0.00024000000000000003"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                fill="white"
                              ></path>
                            </g>
                          </svg>
                        </v-btn>
                        <v-btn
                          href="https://myschool.edu.ru/"
                          target="_blank"
                          style="
                            color: white;
                            background-color: rgb(0, 122, 255);
                            margin: 3px;
                          "
                          text
                        >
                          Моя школа &nbsp;
                          <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 24.00 24.00"
                            id="meteor-icon-kit__regular-external-link"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#000000"
                            stroke-width="0.00024000000000000003"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                fill="white"
                              ></path>
                            </g>
                          </svg>
                        </v-btn>
                        <v-btn
                          href="https://sgo.rso23.ru/authorize/login"
                          target="_blank"
                          style="
                            color: white;
                            background-color: #3063ad;
                            margin: 3px;
                          "
                          text
                        >
                          Сетевой журнал &nbsp;
                          <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 24.00 24.00"
                            id="meteor-icon-kit__regular-external-link"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#000000"
                            stroke-width="0.00024000000000000003"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                fill="white"
                              ></path>
                            </g>
                          </svg>
                        </v-btn>

                        <br />
                        <br />
                        <div style="text-align: center">
                          <b>ACT:</b>
                        </div>

                        <li>
                          <v-btn
                            href="https://brend.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Брэндирование&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ffffff"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://menu.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Заявки меню&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>

                        <li>
                          <v-btn
                            href="https://missing.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Отсутствующие&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>

                        <li>
                          <v-btn
                            href="https://do.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Путеводитель по секциям&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://sgo.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Скрипты проверки сетевого&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://lib.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Библиотека&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://zvonki.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Музыкальные звонки&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://metronom.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Метроном&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ffffff"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                        <li>
                          <v-btn
                            href="https://shedule.actsch24.ru/"
                            target="_blank"
                            text
                          >
                            Составитель расписания (альфа-версия)&nbsp;
                            <svg
                              width="14px"
                              height="14px"
                              viewBox="0 0 24.00 24.00"
                              id="meteor-icon-kit__regular-external-link"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ffffff"
                              stroke-width="0.00024000000000000003"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                                  fill="black"
                                ></path>
                              </g>
                            </svg>
                          </v-btn>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    v-if="check && selectedClass.classID != 'violations'"
                    class="nav-item"
                    style="margin-left: 5px"
                  >
                    <div class="dropdown">
                      <button
                        class="btn btn-outline-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="border-color: white"
                        color="white"
                      >
                        НАСТРОЙКА
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                        style="padding: 2px"
                      >
                        <li v-if="selectedClass.classID === 'admin'">
                          <v-btn to="/registryChange" text>
                            Реестр изменений
                          </v-btn>
                          <v-btn to="/OrderCategory" text>
                            Подтверждение категорий
                          </v-btn>
                          <v-btn to="/class" text> Классы </v-btn>
                        </li>
                        <li>
                          <v-btn to="/CreateStudent" text> Ученики </v-btn>
                        </li>
                        <li>
                          <v-btn to="/PostSettings" text> Объявления </v-btn>
                        </li>
                        <li v-if="selectedClass.classID === 'admin'">
                          <v-btn to="/Categories" text>
                            Категории и причины
                          </v-btn>
                        </li>
                        <li v-if="selectedClass.classID === 'admin'">
                          <v-btn to="/FoodCompensation" text>
                            Компенсация питания
                          </v-btn>
                        </li>
                        <li v-if="selectedClass.classID === 'admin'">
                          <v-btn to="/FoodTypeWeekSettings" text>
                            Меню недели
                          </v-btn>
                        </li>
                        <li v-if="selectedClass.classID === 'admin'">
                          <v-btn to="/globalSettings" text>
                            Глобальные настройки
                          </v-btn>
                        </li>
                        <li class="nav-item">
                          <v-btn to="/Receipt" text> Квитанции </v-btn>
                        </li>
                        <li>
                          <v-btn to="/inventoryControlPanel" text>
                            Инветаризация
                          </v-btn>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-left: 5px">
                    <v-btn
                      class="btn btn-outline-danger"
                      @click="del"
                      to="/"
                      text
                      style="color: azure"
                    >
                      ВЫХОД: {{ selectedClass.className }}
                    </v-btn>
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="timeSession"
              style="font-size: 9px; width: 100px; color: white"
            ></div>
          </nav>
        </div>
      </transition>
    </div>
    <v-main>
      <div
        v-if="$route.meta.keepAlive && !selectedClass.className"
        style="
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
          font-size: 23px;
          color: white;
          background-color: rgb(0, 71, 172);
          height: 100vh;
        "
      >
        <div>Загрузка...</div>

        <br />
        <div
          style="width: 100px; height: 100px"
          class="spinner-border"
          role="status"
        >
          <span class="sr-only"></span>
        </div>
      </div>
      <transition name="component-fade" mode="out-in" appear>
        <router-view
          v-if="!$route.meta.keepAlive"
          @example="methodName"
          :selectedClass="selectedClass"
        ></router-view>
      </transition>
      <keep-alive exclude="OrderCategory">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <div v-if="buttonShow" @click="click" class="button">
        <div class="arrow-1">
          <div></div>
        </div>
        <div style="margin: 45px -10px 0 25px">Вверх!</div>
      </div>
    </v-main>
  </v-app>
</template>
<script
  async
  src="/examples/vendors/bootstrap-3.4/js/bootstrap.min.js"
></script>

<script>
let timeout = 500000; // 900000;
let lastActiveTimestamp = 0;
let userIsActive = false;

window.addEventListener("mousemove", active);
window.addEventListener("keypress", active);
window.addEventListener("click", active);

setInterval(checkUserIsActive, 1000);
active();

async function checkUserIsActive() {
  // const response = await fetch(window.myVariable + "/getDateWorld", {
  //   method: "GET", // *GET, POST, PUT, DELETE, etc.
  //   mode: "cors", // no-cors, *cors, same-origin
  //   cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //   credentials: "same-origin", // include, *same-origin, omit
  //   headers: {
  //     //  "Content-Type": "application/json", //не работает почему-то парс потом
  //     "ngrok-skip-browser-warning": 1,
  //     "Bypass-Tunnel-Reminder": 3,
  //     "Access-Control-Allow-Origin": "*",
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  //   redirect: "follow", // manual, *follow, error
  //   // referrerPolicy: "no-referrer", // no-referrer, *client
  // });
  // console.log(await response.json());
  if (document.getElementById("timeSession")) {
    let seconds = (
      (timeout - (new Date().getTime() - lastActiveTimestamp)) /
      1000
    ).toFixed(0);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    document.getElementById("timeSession").innerHTML =
      "<div>" +
      minutes +
      " минут" +
      "</div>" +
      "<div>" +
      remainingSeconds +
      " секунд" +
      "</div>" +
      "<div>" +
      "до выхода" +
      "</div>";
  }

  if (userIsActive && new Date().getTime() - lastActiveTimestamp > timeout) {
    userIsActive = false;
  }
}

function active() {
  lastActiveTimestamp = new Date().getTime();

  if (!userIsActive) {
    userIsActive = true;
  }
}

export default {
  name: "app",
  data: function () {
    return {
      buttonShow: false,
      router: this.$router.currentRoute.name,
      localStorage: localStorage,
      check: false,
      selectedClass: {},
      timer: "",
    };
  },
  methods: {
    checked() {
      console.log(localStorage.user);
      console.log(this.selectedClass);
    },

    methodName(variable) {
      this.selectedClass = variable;
    },
    del() {
      localStorage.clear();
    },

    checkScrollPosition() {
      this.buttonShow = window.pageYOffset > 750;
    },
    click() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
    },

    get() {
      this.checkActive();
    },
    checkActive() {
      if (!userIsActive && this.$router.currentRoute.name != "login") {
        localStorage.clear();
        this.$router.push("/login");
      }
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  async mounted() {
    //  await this.delay(600);
    if (this.selectedClass.className === undefined) {
      localStorage.clear();
      this.$router.push("/login");
    }
    this.timer = setInterval(this.get, 1000);
    this.checkScrollPosition();
    window.addEventListener("scroll", this.checkScrollPosition);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (this.$router.currentRoute.name !== "login") {
          this.check = true;
        } else {
          this.check = false;
        }
      },
    },
  },
};
</script>
<style lang="scss">
.v-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.2s ease-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
  transform: translateY(100px);
}

.content {
  height: 1000px;
  background-color: #e7e7e7;
}

.button {
  color: rgb(0, 0, 0);

  position: fixed;
  left: 20px;
  bottom: 25px;
  cursor: pointer;
  padding: 10px 30px 10px 0px;
  margin: 0 0 10px 0;
  background-color: #b4ffcd48;
  border-radius: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 1000;
}

.arrow-1 {
  transform: rotate(-90deg);
  cursor: pointer;
  position: relative;

  margin: 20px 0 20px -10px;
}
.arrow-1 div {
  position: relative;
  top: 20px;
  width: 50px;
  height: 10px;
  background-color: #33b733;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  display: block;
}
.arrow-1 div::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 10px;
  top: -11px;
  right: -8px;
  background-color: #33b733;
  transform: rotate(45deg);
}
.arrow-1 div::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 10px;
  top: 11px;
  right: -8px;
  background-color: #33b733;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(-45deg);
}
.button:hover {
  animation: arrow-1 1s linear infinite;
}
@keyframes arrow-1 {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 25px;
  }
  100% {
    bottom: 20px;
  }
}
li {
  list-style-type: none;
}

.dropdown-menu .dropdown-menu {
  top: auto;
  left: 100%;
  transform: translateY(-2rem);
}
.dropdown-item + .dropdown-menu {
  display: none;
}
.dropdown-item.submenu::after {
  content: "▸";
  margin-left: 0.5rem;
}
.dropdown-item:hover + .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}
</style>
